import React from "react";

export enum ROUTES_QUERY {
  male = "male",
  female = "female",
  anime_male = "anime_male",
  anime_female = "anime_female",
}

export const ROUTES = {
  BASE: "/",
  HOME: "/explore",
  SUBSCRIBE: "/subscribe",
  PLANS: "/plans",
  REGISTER: "/register",
  LOGIN: "/login",
  RESET: "/reset",
  GALLERY: "/gallery",
  GALLERY_ID: "/gallery/:id",
  ADMIN: "/admin",
  CHAT: "/chat",
  CREATE_AI: "/create_ai",
  MY_AI: "/my_ai",
  MY_AIS: "/my_ais",
  MY_AIS_ID: "/my_ais/:id",
  GENERATE_IMAGE: "/generate_image",
  CHAT_ID: "/chat/:id",
  RESET_PASSWORD: "/restore",
  RESTORE_PASSWORD: "/restore",
  SETTINGS: "/settings",
  PAYMENT: "/payment",
  PRIVACY_POLICY: "/privacy_policy",
  TERMS: "/terms",
  SUBID: "/:id",
  PAYWALL: "/paywall",
  NOT_FOUND: "*",
} as const;
