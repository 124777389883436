import styles from "./styles.module.css";

interface IProps {
  width?: number;
  borderColor?: string;
}

const Spinner: React.FC<IProps> = ({ width = 30, borderColor = "white" }: IProps) => {
  let borderWidth = 3;

  if (width <= 20) {
    borderWidth = 2;
  }

  const borderBottomColor = "transparent";

  return (
    <span
      style={{ width, height: width, borderWidth, borderColor, borderBottomColor }}
      className={styles.loader}
    ></span>
  );
};

export default Spinner;
