import styles from "./styles.module.css";
import { Spinner } from "@shared/ui/Spinner";

const InitLoader: React.FC = () => {
  return (
    <div className={styles.loading_container}>
      <Spinner borderColor="#ff3a2e" />
    </div>
  );
};

export default InitLoader;
