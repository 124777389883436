import { GENDER, IIA } from "@shared/interfaces";
import { create } from "zustand";
import { axiosInstance } from "@shared/utils/configs/axiosInstance";
import { url } from "@shared/libs";

type CustomImageState = {
  data: string[];
  customModels: IIA[];
  error: null | string;
  isGenerating: boolean;
  isGenerated: boolean;
  isGeneratingError: boolean;
  createdAiId: number | null;
  startGeneration: () => void;
  createMyAi: (ai: IIA) => Promise<void>;
  endGeneration: (data: string[]) => void;
  failGeneration: (error: string) => void;
  getCustomModels: () => Promise<void>;
  createCustomImage: (count: number, model_id: number, user_prompt: string) => Promise<any>;
};

export const useCustomImageStore = create<CustomImageState>((set) => ({
  data: [],
  error: null,
  isGenerating: false,
  isGenerated: false,
  isGeneratingError: false,
  createdAi: null,
  createdAiId: null,
  customModels: [],
  startGeneration: () => {
    set({
      isGenerating: true,
      isGenerated: false,
      isGeneratingError: false,
      data: [],
    });
  },
  endGeneration: (data: string[]) => {
    set({ isGenerating: false, isGenerated: true, data });
  },
  failGeneration: (error: string) => {
    set({ isGenerating: false, data: [], error });
  },
  createMyAi: async (ai: IIA) => {
    try {
      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const body = {
        ...ai,
        age: +ai.age,
        hobbies: ai.hobbies.join(","),
        body_type: ai.body_type.value,
        breast_type: ai.breast_type.value,
        butt_size: ai.butt_size.value,
        ethnicity: ai.ethnicity.value,
        eyes_color: ai.eyes_color.value,
        hair_style: ai.hair_style.value,
        personality: ai.personality.value,
        relationship: ai.relationship.value,
      };

      const { data } = await axiosInstance.post("/api/custom/models", body, axiosConfig);

      set({ createdAiId: data.model_id });

      return data;
    } catch (e: any) {
      console.log(e);

      if (e?.response?.status === 402 && e?.response?.data.error === "insufficient_balance") {
        return e?.response?.data;
      }
    }
  },
  getCustomModels: async () => {
    try {
      let genderType = localStorage.getItem("isStartedChoosing");

      if (!genderType) {
        genderType = GENDER.female;
      }

      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const { data } = await axiosInstance.get("api/custom/models?gender=" + genderType, axiosConfig);
      set({ customModels: data.items });
    } catch (e: any) {
      console.log(e);
    }
  },
  createCustomImage: async (count: number, model_id: number, user_prompt: string) => {
    try {
      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const url = `/api/custom/images`;

      const { data } = await axiosInstance.post<any>(url, { count, model_id, user_prompt }, axiosConfig);

      return data;
    } catch (e) {
      console.log(e);
      return e;
    }
  },
}));
