import styles from "./styles.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import { createBrowserRouter, RouterProvider, useLocation } from "react-router-dom";
import { Routes, useRoutes, useLocation, BrowserRouter, useNavigate } from "react-router-dom";
//import { RouterBuilder } from "./config/routes";
import { IUserState, useUserStore } from "@entities/user";
import { Portal } from "@widgets/portal";
import { Interested } from "@widgets/interested";
import { UserAuthContextProvider } from "@shared/model";
import { ErrorBoundary } from "react-error-boundary";
import { IServerMessage, ROUTES } from "@shared/interfaces";
import { SkeletonTheme } from "react-loading-skeleton";
import React, { useEffect, useMemo, useState } from "react";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import "react-loading-skeleton/dist/skeleton.css";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { useCustomImageStore } from "@entities/customImage";

import { Cookie } from "@widgets/cookie";
import { Restrictions } from "@widgets/restrictions";
import { InitLoader } from "@widgets/auth/initLoader";
import RouterBuilder from "./config/routes";

const INTERVAL = 1500;
const PING_PONG_INTERVAL = 30000;

const SUB_ID = "subid";
const I_AM_OVER_18 = "IamOver18";
const ACCESS_TOKEN = "_accessToken";
const IS_COOKIES_SHOW = "isCookieShow";
const IS_STARTING_CHOOSING = "isStartedChoosing";

const App: React.FC = () => {
  const navigate = useNavigate();
  const user = useUserStore((state: IUserState) => state.user);
  const { endGeneration } = useCustomImageStore();
  const isAuth = Boolean(user?.id);
  const [isCookieShow, setCookieShow] = useState<boolean>(false);
  const [isStartedChoosingState, setStartedChoosingState] = useState<boolean>(true);
  const isLoadingShow = useUserStore((state: IUserState) => state.isLoadingShow);

  const { model }: any = useModelStore((state: IModelState) => state.modelActive);
  const { saveAdToken, getPlans, getTokens, setSpinner }: any = useUserStore((state: IUserState) => state);
  const { setMessage, setIsTyping, setDisableBtn, setLastMessage, setGenerationProgress, setScrollToLast } =
    useModelStore((state: IModelState) => state);

  if (model?.id) {
    (window as any).model = model;
  }

  useEffect(() => {
    const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;
    const AMPLITUDE_REPLAY = process.env.REACT_APP_AMPLITUDE_REPLAY || 1;
    amplitude.init(AMPLITUDE_KEY || "", { autocapture: true });

    const sessionReplayTracking = sessionReplayPlugin({ sampleRate: +AMPLITUDE_REPLAY });
    amplitude.add(sessionReplayTracking);

    console.log("[AMPLITUDE_REPLAY]", AMPLITUDE_REPLAY);

    if (!localStorage.getItem(IS_COOKIES_SHOW) && localStorage.getItem(I_AM_OVER_18)) {
      setCookieShow(true);
    }

    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible" && (window as any)?.socket?.readyState !== WebSocket.OPEN) {
        console.log("Tab is active. Checking WebSocket connection...");
        socketConnection();
      }
    });
  }, []);

  useEffect(() => {
    async function fetchApi() {
      await getPlans();
      await getTokens();
    }

    fetchApi();
  }, []);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    socketConnection();

    if (localStorage.getItem(SUB_ID)) {
      saveAdToken();
    }
  }, [isAuth]);

  const routesConfig = useMemo(() => {
    const builder = new RouterBuilder();

    return isAuth ? builder.build() : builder.addAuthRouters().build();
  }, [isAuth]);

  const routes = useRoutes(routesConfig);

  if (isLoadingShow) {
    return <InitLoader />;
  }

  const onRestrictionClose = () => {
    localStorage.setItem(I_AM_OVER_18, "1");
    setCookieShow(true);
  };

  const renderPreferences = () => {
    if (!localStorage.getItem(I_AM_OVER_18)) {
      return;
    }

    const allowsRoutes: string[] = [ROUTES.GALLERY, ROUTES.HOME, ROUTES.CHAT, ROUTES.SUBSCRIBE, ROUTES.GENERATE_IMAGE];

    const pathName = window.location.pathname;
    const finedRouter = allowsRoutes.some((route) => route.includes(pathName));

    const isStartedChoosing = localStorage.getItem(IS_STARTING_CHOOSING);
    if (!isStartedChoosing && finedRouter && isStartedChoosingState) {
      return (
        <Portal>
          <Interested onClose={() => setStartedChoosingState(false)} />
        </Portal>
      );
    }
  };

  const renderRestriction = () => {
    const over18 = localStorage.getItem(I_AM_OVER_18);

    if (over18) {
      return <></>;
    }

    const allowsRoutes: string[] = [ROUTES.TERMS, ROUTES.PRIVACY_POLICY];

    const pathName = window.location.pathname;
    const finedRouter = allowsRoutes.some((route) => route === pathName);

    if (!finedRouter) {
      return (
        <Portal>
          <Restrictions onClose={onRestrictionClose} />
        </Portal>
      );
    }
  };

  function isOpen(ws: any) {
    return ws?.readyState === ws?.OPEN;
  }

  function socketConnection() {
    const { REACT_APP_WS_URL } = process.env;
    const token = localStorage.getItem(ACCESS_TOKEN);

    if (!isOpen((window as any).socket)) {
      clearInterval((window as any).interval);
      (window as any).socket.close();
    }

    (window as any).socket = new WebSocket(`${REACT_APP_WS_URL}?token=${token}`);

    (window as any).socket.onopen = () => {
      (window as any).interval = null;

      clearInterval((window as any).interval);
      console.log(`[WSS]: connected`);

      setPingPongConnection();
    };

    (window as any).socket.onmessage = (message: any) => {
      const gettingMessage = message.data;

      if (gettingMessage === "pong") {
        return;
      }

      const messageToJson = JSON.parse(gettingMessage);
      const { action } = messageToJson;

      if (action === "add" || action === "update") {
        getMessages(messageToJson.data);
      }

      if (action === "update_custom_image") {
        endGeneration(messageToJson.data.images_urls);
      }

      if (action === "custom_model_created") {
        const id = messageToJson.data.model_id;

        amplitude.track(`Constructor Character Generated`, {
          characterId: messageToJson.data.id,
        });

        const callback = localStorage.getItem("callback");
        const isFree = user?.plan === "free" ? true : false;

        if (callback === "paywall" && isFree) {
          localStorage.setItem("paywall_ai", JSON.stringify(messageToJson.data));
          window.location.href = `${ROUTES.MY_AI}?callback=paywall`;
        } else {
          localStorage.removeItem("paywall");
          window.location.href = `${ROUTES.MY_AIS}/${id}`;
        }
      }
    };

    (window as any).socket.onclose = (e: any) => {
      console.log("[WS]: socket is closed.", e.reason);

      (window as any).interval = setTimeout(() => {
        socketConnection();
      }, INTERVAL);
    };

    (window as any).socket.onerror = (e: any) => {
      console.log("[WS]: socket is closed.", e);

      (window as any).socket.close();
    };

    const setPingPongConnection = () => {
      console.log(`[WSS]: ping-pong connection`);
      //TODO clear interval
      clearInterval((window as any).intervalID);
      (window as any).intervalID = null;

      (window as any).intervalID = setInterval(() => {
        if (!isOpen((window as any).socket)) return;
        (window as any).socket.send("ping");
      }, PING_PONG_INTERVAL);
    };
  }

  const getMessages = (message: any) => {
    console.log("====>Callback", message);
    messagesGateWay(message);
  };

  const messagesGateWay = (message: IServerMessage) => {
    const chatId = +message.chat_id;
    let scrollToViewFlag = "";

    const MESSAGE_TYPE_TYPING = "typing";
    const MESSAGE_TYPE_IMAGE = "image";
    const MESSAGE_TYPE_PRIVATE_IMAGE = "private_image";
    const MESSAGE_TYPE_PRIVATE_VIDEO = "private_video";
    const MESSAGE_TYPE_TEXT = "text";
    const MESSAGE_TYPE_START_GENERATION = "start_generation";

    setDisableBtn(true);
    if (message.type === MESSAGE_TYPE_TYPING) {
      setIsTyping(true, message);
      scrollToViewFlag = "typingMessage";
    }

    if (message.type === MESSAGE_TYPE_START_GENERATION) {
      setGenerationProgress(true, message);
      scrollToViewFlag = "generateMessage";
    }

    if (
      message.type === MESSAGE_TYPE_IMAGE ||
      message.type === MESSAGE_TYPE_TEXT ||
      message.type === MESSAGE_TYPE_PRIVATE_IMAGE ||
      message.type === MESSAGE_TYPE_PRIVATE_VIDEO
    ) {
      setIsTyping(false);
      setMessage(message);
      setDisableBtn(false);
      setGenerationProgress(false, message);

      scrollToViewFlag = "imageOrTextMessage";

      if (model.id) {
        amplitude.track(`Message Received from AI`, {
          characterId: (window as any).model.id,
          characterName: (window as any).model.name,
          aiResponseType: message.type,
        });
      }
    }

    setLastMessage(chatId, message);
    setScrollToLast(scrollToViewFlag);
  };

  const onCookieClose = () => {
    localStorage.setItem(IS_COOKIES_SHOW, "1");
    setCookieShow(false);
  };

  return (
    <>
      {isCookieShow && <Cookie onClose={onCookieClose} />}
      {renderRestriction()}
      {renderPreferences()}
      {routes}
    </>
  );
};

const AppWithProvider: React.FC = () => {
  return (
    <div className={styles.root__container}>
      <BrowserRouter>
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <UserAuthContextProvider>
            <SkeletonTheme baseColor="#2C2C33" highlightColor="#1E1E23" duration={1.2}>
              <App />
            </SkeletonTheme>
          </UserAuthContextProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
};

export default AppWithProvider;
