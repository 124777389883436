import { IFormEmailState, IFormState } from "@widgets/auth/loginForm";
import { auth } from "app/host/config/firebase";
import {
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  TwitterAuthProvider,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { IUserState, useUserStore } from "@entities/user";

const SUB_ID = "subid";
const SOURCE = "source";
const RETURN_URL = "returnUrl";
const AUTH_RETURN_URL = "authReturnUrl";

const context = {
  isLoading: false,
  logIn: (formData: IFormState) => {},
  signUp: (formData: IFormState) => {},
  restorePasswordByEmail: (formData: IFormEmailState) => {},
  loginSocialGoogle: () => {},
  loginSocialDiscord: (token: string) => {},
  loginSocialTwitter: () => {},
  logOut: () => {},
};

const UserAuthContext = createContext(context);

export const useUserAuth = () => {
  return useContext(UserAuthContext);
};

interface IProps {
  children: React.ReactElement;
}

export const UserAuthContextProvider = ({ children }: IProps) => {
  const [isLoading] = useState(true);
  const { setUser, sendAdsInfo, setUserToAmplitude, setLoading } = useUserStore((state: IUserState) => state);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser: any) => {
      console.log("Auth", currentUser);

      setLoading(false);

      if (!currentUser) {
        return;
      }

      const user = await setUser(currentUser);

      const source = localStorage.getItem(SOURCE);

      if (source === "signup") {
        await sentInfoToTracker();
      }

      if (user?.id) {
        await setUserToAmplitude(user.id);
      }

      const returnUrl = localStorage.getItem(RETURN_URL);
      const authReturnUrl = localStorage.getItem(AUTH_RETURN_URL);

      if (returnUrl) {
        localStorage.removeItem(RETURN_URL);
        localStorage.removeItem(AUTH_RETURN_URL);
        window.location.replace(`${returnUrl}`);

        return;
      }

      if (authReturnUrl) {
        localStorage.removeItem(AUTH_RETURN_URL);
        window.location.replace(`${authReturnUrl}`);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setUser]);

  const logIn = async (formData: IFormState) => {
    return signInWithEmailAndPassword(auth, formData.email, formData.password).catch((error) => {
      if (error.code === "auth/invalid-credential") {
        throw new Error("Wrong email or password.");
      }
    });
  };

  const signUp = async (formData: IFormState) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);

      const user = userCredential.user;

      if (user) {
        await sentInfoToTracker();
      }

      return userCredential;
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use") {
        throw new Error("Email already exist.");
      }
      console.error("Signup error:", error.message);
    }
  };

  const restorePasswordByEmail = (formData: IFormEmailState) => {
    return sendPasswordResetEmail(auth, formData.email);
  };

  const logOut = () => {
    return signOut(auth);
  };

  const loginSocialGoogle = () => {
    const googleAuthProvider = new GoogleAuthProvider();

    return signInWithPopup(auth, googleAuthProvider);
  };

  const loginSocialTwitter = () => {
    const twitterAuthProvider = new TwitterAuthProvider();

    return signInWithPopup(auth, twitterAuthProvider);
  };

  const loginSocialDiscord = (token: string) => {
    return signInWithCustomToken(auth, token);
  };

  const sentInfoToTracker = async () => {
    const subid = localStorage.getItem(SUB_ID);

    if (subid) {
      await sendAdsInfo({ subid, status: "lead" });
    }

    localStorage.removeItem(SOURCE);
  };

  const context = {
    isLoading,
    logIn,
    signUp,
    logOut,
    loginSocialGoogle,
    loginSocialTwitter,
    loginSocialDiscord,
    restorePasswordByEmail,
  };

  return <UserAuthContext.Provider value={context}>{children}</UserAuthContext.Provider>;
};
