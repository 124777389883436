import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";

const HomePage = lazy(() => import("@pages/home/ui/Home"));
const GalleryPage = lazy(() => import("@pages/gallery/ui/Gallery"));
const ChatPage = lazy(() => import("@pages/chat/ui/Chat"));
const LoginPage = lazy(() => import("@pages/signIn/ui/Login"));
const RegisterPage = lazy(() => import("@pages/signUp/ui/SignUp"));
const ForgotPasswordPage = lazy(() => import("@pages/forgotPassword/ui/Forgot"));
const NotFound = lazy(() => import("@pages/404/ui/404"));
const SubscribePage = lazy(() => import("@pages/subscribe/ui/Subscribe"));
const PlansPage = lazy(() => import("@pages/plans/ui/Plans"));
const PaymentPage = lazy(() => import("@pages/payment/ui/Payment"));
const PrivacyPolicy = lazy(() => import("@pages/privacy_policy/ui/PrivacyPolicy"));
const Terms = lazy(() => import("@pages/terms/ui/Terms"));
const CreateAi = lazy(() => import("@pages/createAI/ui/CreateAi"));
const GenerateImage = lazy(() => import("@pages/generateImage/ui/GenerateImagePage"));
const SettingsPage = lazy(() => import("@pages/settings/ui/Settings"));
const MyAiPage = lazy(() => import("@pages/myAi/ui/MyAi"));
const GalleryItemPage = lazy(() => import("@pages/galleryItem/ui/GalleryItem"));

export default class RouterBuilder {
  private baseRouters: any[] = [
    { path: ROUTES.BASE, element: <HomePage /> },
    { path: ROUTES.HOME, element: <HomePage /> },
    { path: ROUTES.SUBID, element: <HomePage /> },
    { path: ROUTES.SETTINGS, element: <SettingsPage /> },
    { path: ROUTES.GALLERY, element: <GalleryPage /> },
    { path: ROUTES.GALLERY_ID, element: <GalleryItemPage /> },

    { path: ROUTES.CHAT, element: <ChatPage /> },
    { path: ROUTES.CHAT_ID, element: <ChatPage /> },
    { path: ROUTES.SUBSCRIBE, element: <SubscribePage /> },
    { path: ROUTES.PLANS, element: <PlansPage /> },
    { path: ROUTES.PAYMENT, element: <PaymentPage /> },
    { path: ROUTES.PRIVACY_POLICY, element: <PrivacyPolicy /> },
    { path: ROUTES.TERMS, element: <Terms /> },
    { path: ROUTES.CREATE_AI, element: <CreateAi /> },
    { path: ROUTES.GENERATE_IMAGE, element: <GenerateImage /> },
    { path: ROUTES.MY_AI, element: <MyAiPage /> },
    { path: ROUTES.MY_AIS_ID, element: <MyAiPage /> },
    { path: ROUTES.NOT_FOUND, element: <NotFound /> },
    { path: "*", element: <Navigate to={ROUTES.NOT_FOUND} /> },
  ];

  private authRouter: any[] = [
    { path: ROUTES.LOGIN, element: <LoginPage /> },
    { path: ROUTES.REGISTER, element: <RegisterPage /> },
    { path: ROUTES.RESET_PASSWORD, element: <ForgotPasswordPage /> },
    { path: ROUTES.GALLERY, element: <GalleryPage /> },
    { path: ROUTES.CHAT, element: <ChatPage /> },
    { path: ROUTES.CHAT_ID, element: <ChatPage /> },
  ];

  addAuthRouters(): this {
    this.baseRouters = [...this.baseRouters, ...this.authRouter];
    return this;
  }

  removeAuthRouters(): this {
    this.baseRouters = this.baseRouters.filter(
      (route) => ![ROUTES.LOGIN, ROUTES.REGISTER, ROUTES.RESET_PASSWORD].includes(route.path)
    );
    return this;
  }

  build(): any[] {
    return this.baseRouters.map((route) => ({
      ...route,
      element: <Suspense fallback={<div>Loading...</div>}>{route.element}</Suspense>,
    }));
  }
}
