import { IFBUser, IUserState } from "./user.types";
import { axiosInstance, axiosStaticInstance } from "@shared/utils/configs/axiosInstance";
import { create } from "zustand";
import * as amplitude from "@amplitude/analytics-browser";
import { url } from "@shared/libs";

export const useUserStore = create<IUserState>((set, get) => {
  return {
    user: {
      plan: "",
      tokens: 0,
      email: null,
      free_msgs: 0,
      last_name: "",
      first_name: "",
      gender_choice: "",
      payment_failed: false,
      id: null,
    },
    isSpinnerShow: false,
    isLoadingShow: true,
    isSubscriptionRenew: false,
    subscriptionTokens: [],
    subscriptionPlans: [],
    monthlyTokens: 0,
    setUser: async (userData: any) => {
      try {
        if (userData?.accessToken) {
          localStorage.setItem("_accessToken", userData.accessToken);
          const startedChoosing = localStorage.getItem("isStartedChoosing");

          const modifyString = (startedChoosing && startedChoosing.replace("?type=", "")) || "";

          const { data } = await axiosInstance.get<IFBUser>("/api/user", {
            headers: {
              Authorization: userData.accessToken,
            },
          });

          if (!data?.gender_choice && startedChoosing) {
            await get().setUserChoice(modifyString);

            data.gender_choice = modifyString;
          }

          set({ user: { ...data } });
          // payment_failed

          return data;
        } else {
          if (!userData) {
            set({ user: {} });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    setUserToAmplitude: async (userId: string) => {
      const USER_ID = "user_id";

      amplitude.setUserId(userId);
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set(USER_ID, userId);
      amplitude.identify(identifyEvent);
    },
    getUser: async () => {
      try {
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        const { data } = await axiosInstance.get<IFBUser>("/api/user", axiosConfig);

        set({ user: { ...data } });
      } catch (e) {
        console.log(e);
      }
    },
    setUserChoice: async (choice: string) => {
      const modifyString = choice && choice.replace("?type=", "");

      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const body = {
        gender_choice: modifyString,
      };

      try {
        await axiosInstance.patch<IFBUser>("/api/user", body, axiosConfig);
        const currentUser = get().user;

        set({ user: { ...currentUser, gender_choice: choice } });
      } catch (e) {
        console.log(e);
      }
    },
    setPayment: async (price: string | any, method: string) => {
      try {
        const url = "/api/user/payment";
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        let body = {};
        if (price) {
          body = { tokens: +price };
        } else {
          body = { plan: "premium" };
        }

        await axiosInstance.patch(url, body, axiosConfig);

        const { data } = await axiosInstance.get<IFBUser>("/api/user", axiosConfig);

        set({ user: { ...data } });
      } catch (e) {
        if (price) {
          amplitude.track(`Token Purchase Failed`, {
            paymentMethod: method,
          });
        } else {
          amplitude.track(`Payment Failed`, {
            paymentMethod: method,
          });
        }
        console.log(e);
      }
    },
    setTokens: (tokens: number) => {
      const user = get().user;

      user.tokens = tokens;

      set({ user });
    },
    sendAdsInfo: async (query: any) => {
      try {
        const queryString = url.generateQueryString(query);
        await fetch(`https://getflirt-ai-trc.com/1410e72/postback?${queryString}`);
      } catch (e) {
        console.log(e);
      }
    },
    saveAdToken: async () => {
      const subid = localStorage.getItem("subid") || "";

      try {
        const { data } = await axiosInstance.patch(
          "/api/user/analytics",
          { new_id: subid },
          {
            headers: {
              Authorization: localStorage.getItem("_accessToken"),
            },
          }
        );

        localStorage.removeItem("subid");
        localStorage.setItem("subid_sended", subid);
      } catch (e) {
        console.log(e);
      }
    },
    getPaymentUrl: async (activeSearchParams: any) => {
      try {
        const searchParams = `${url.generateQueryString(activeSearchParams)}`;

        const fullUrl = `/api/user/payment_popup?${searchParams}`;

        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        const { data } = await axiosInstance.get(fullUrl, axiosConfig);

        if (data?.url) {
          return data.url;
        }
      } catch (e) {
        console.error("Can't get payment url");
      }
    },
    setSpinner: (flag: boolean) => {
      set({ isSpinnerShow: flag });
    },
    setLoading: (flag: boolean) => {
      set({ isLoadingShow: flag });
    },
    cancelSubscription: async () => {
      const fullUrl = `/api/payment/cancel`;

      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      await axiosInstance.post(fullUrl, {}, axiosConfig);
    },
    setSubscriptionRenew: (flag: boolean) => {
      set({ isSubscriptionRenew: flag });
    },
    getUpdateSubscriptionUrl: async (activeSearchParams: any) => {
      try {
        const searchParams = `${url.generateQueryString(activeSearchParams)}`;

        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        const { data } = await axiosInstance.post(`/api/payment/update?${searchParams}`, {}, axiosConfig);

        if (data?.url) {
          return data.url;
        }
      } catch (e) {
        console.log(e);
      }
    },
    getPlans: async () => {
      const fullUrl = `/api/subscriptions/plans`;

      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const { data } = await axiosInstance.get(fullUrl, axiosConfig);
      set({ subscriptionPlans: data?.plans?.reverse() || [] });
      set({ monthlyTokens: data?.features?.MonthlyTokens || 0 });
    },
    getTokens: async () => {
      const fullUrl = `/api/subscriptions/tokens`;

      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const { data } = await axiosInstance.get(fullUrl, axiosConfig);
      set({ subscriptionTokens: data?.tokens || [] });
    },
  };
});
