import { ButtonFactory } from "@shared/ui/Button";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";

interface IProps {
  onClose: () => void;
}

const Restrictions = ({ onClose }: IProps) => {
  const onActionClick = (action: string) => {
    if (action === "terms") {
      window.location.replace(`${ROUTES.TERMS}`);
    }

    if (action === "privacy") {
      window.location.replace(`${ROUTES.PRIVACY_POLICY}`);
    }
  };

  return (
    <div className={styles.restriction_container}>
      <div className={styles.restriction_content}>
        <h2>Warning 18+</h2>
        <h5 className={styles.subtitle}>This site is for adults only!</h5>
        <h5 className={styles.subtitle}>It contains Al-generated adult content.</h5>

        <h6 className={styles.text}>
          By entering this website, you confirm that you are 18 years old or more. Providing inaccurate information
          about your age constitutes a violation of our Terms of Service. By using the site, you agree to our Terms of
          Service and Policies. Our privacy policy details how we collect and use your data, including your email
          address for marketing purposes. By continuing to use this site, you consent to receive email updates and
          offers from us, which you may opt out of at any time. We use cookies for basic analytics and spam detection.
          All content on this website is Al-generated! Many generations that resemble real people are purely
          coincidental.
        </h6>

        <div className={styles.actions}>
          <ButtonFactory
            variant="primary"
            label="Terms of Service"
            type="button"
            onClick={() => onActionClick("terms")}
          />
          <ButtonFactory
            variant="primary"
            label="Privacy Policy"
            type="button"
            onClick={() => onActionClick("privacy")}
          />
        </div>

        <ButtonFactory variant="danger" label="I am over 18 - Continue" type="button" onClick={onClose} />
      </div>
    </div>
  );
};

export default Restrictions;
