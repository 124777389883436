export enum GENDER {
  male = "male",
  female = "female",
  anime_male = "anime_male",
  anime_female = "anime_female",
}

export enum MESSAGE_TYPES {
  text = "text",
  image = "image",
}

export enum MEDIA_TYPES {
  photo = "photo",
  video = "video",
  chatImage = "chat_image",
  bundleImage = "bundle_image",
  bundleVideo = "bundle_video",
}

export interface IModel {
  id?: number | undefined;
  name?: string;
  description?: string;
  gender?: GENDER;
  photo?: string;
  personality?: string;
  hobbies?: string;
  occupation?: string;
  availability?: string;
  images_count?: number;
  age?: number;
  user_first_msg_help?: string;
  first_msg?: string;
}

export interface IGalleryItem {
  image_url?: string;
  video_url?: string;
  video_duration?: number;
  is_blured: boolean;
  type: string;
}

export interface IGallery {
  avatar: string;
  media_count: number;
  model_id: number;
  preview: string;
  name: string;
  last_media: IGalleryItem;
}

export interface IBundle extends IModel {
  bundles_count: number;
}

export interface IBundleContent {
  id: number;
  media_type: MEDIA_TYPES;
  media_url: string;
}

export interface IBundleItem {
  id: number;
  description: string;
  photos: number;
  videos: number;
  tokens: number;
  has_access: boolean;
  items: IBundleContent[];
}

export interface IMessage {
  id?: string;
  from: string;
  images_urls?: string[];
  send_at?: string;
  text: string;
  type?: string;
  idDeleted?: boolean;
  is_blured?: boolean;
  media_url?: string[];
  video?: string;
  photo?: string;
}

export interface IServerMessage extends IMessage {
  chat_id: string;
}

export interface IChatItem {
  id?: number | null;
  model: IModel;
  last_message: IMessage;
}

export interface IChatBarModel {
  id?: number;
  name?: string;
  photo?: string;
  messages?: IMessage[];
}

export interface IInterested {
  title: string;
  icon: string;
  background: string;
  path: string;
}

export interface IGalleryFilter {
  items: any[];
  pages_count: number;
  total_count: number;
}

export interface IPersonalityKey {
  icon: string;
  value: string;
}

export interface IIA {
  age: string;
  gender: string;
  isFinish: boolean;
  isReady: boolean;
  hobbies: string[];
  hair_color: string;
  occupation: string;
  eyes_color: IPersonalityKey;
  butt_size: IPersonalityKey;
  body_type: IPersonalityKey;
  ethnicity: IPersonalityKey;
  breast_type: IPersonalityKey;
  hair_style: IPersonalityKey;
  personality: IPersonalityKey;
  relationship: IPersonalityKey;
  name?: string;
}
