import { sortByDateDesc } from "@shared/libs";
import { IModelState } from "./model.types";
import { axiosInstance } from "@shared/utils/configs/axiosInstance";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { IChatItem, IGalleryFilter, IMessage, IModel, IBundle, IGallery } from "@shared/interfaces";

const lastMessage: IMessage = {
  from: "",
  images_urls: undefined,
  send_at: "",
  text: "",
};

const model: IModel = {
  id: undefined,
  name: "",
  description: "",
  user_first_msg_help: "",
  first_msg: "",
};

const modelActive: IChatItem = {
  id: null,
  model,
  last_message: lastMessage,
};

const galleryFilter: IGalleryFilter = {
  items: [],
  pages_count: 0,
  total_count: 0,
};

const IS_STARTING_CHOOSING = "isStartedChoosing";

export const useModelStore = create<IModelState>()(
  immer((set, get) => ({
    models: [],
    gallery: [],
    galleryPack: {},
    messages: [],
    totalCount: 0,
    modelsChats: [],
    modelsBundles: [],
    modelBundles: [],
    modelsPack: [],
    isTyping: false,
    disabledBtn: true,
    modelActive: modelActive,
    isModelsChatsLoading: true,
    isModelActiveLoading: true,
    isMessagesLoading: false,
    galleryFilters: galleryFilter,
    isGenerationInProgress: false,
    isSubscriptionSuccessful: false,
    isPaymentFailed: false,
    isAddTokensSuccessful: false,
    isGetTokenBannerShow: false,
    isUpdatePlanShow: false,
    isBuyTokensShow: false,
    scrollToLast: "",
    setDisableBtn: (state: boolean) => {
      set({ disabledBtn: state });
    },
    setSubscriptionSuccessful: (flag: boolean) => {
      set({ isSubscriptionSuccessful: flag });
    },
    setPaymentFailed: (flag: boolean) => {
      set({ isPaymentFailed: flag });
    },
    setAddTokensSuccessful: (flag: boolean) => {
      set({ isAddTokensSuccessful: flag });
    },
    setGetTokenBanner: (flag: boolean) => {
      set({ isGetTokenBannerShow: flag });
    },
    setUpdatePlan: (flag: boolean) => {
      set({ isUpdatePlanShow: flag });
    },
    setBuyTokens: (flag: boolean) => {
      set({ isBuyTokensShow: flag });
    },
    fetchGallery: async () => {
      try {
        const url = "api/v2/gallery";

        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        const { data } = await axiosInstance.get<{ items: IGallery[] }>(url, axiosConfig);

        set({ gallery: data.items });
      } catch (e) {
        console.log(e);
      }
    },
    fetchGalleryPack: async (id: number) => {
      try {
        const url = `api/v2/gallery/${id}`;

        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        const { data } = await axiosInstance.get<{ items: IGallery[] }>(url, axiosConfig);

        set({ galleryPack: data });
      } catch (e) {
        console.log(e);
      }
    },
    fetchGalleryFilters: async () => {
      try {
        const url = "api/gallery/models";
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        const { data } = await axiosInstance.get<IGalleryFilter>(url, axiosConfig);

        set({ galleryFilters: data });
      } catch (e) {
        console.log(e);
      }
    },
    fetchModels: async (genderType: string) => {
      try {
        const url = "api/models?gender=" + genderType;
        //const url = "api/models";
        const { data } = await axiosInstance.get<{ items: IModel[] }>(url);

        set({ models: data.items });
      } catch (e) {
        console.log(e);
      }
    },
    fetchModelsChats: async (search: any = "", flag?: boolean) => {
      if (!search) {
        search = localStorage.getItem(IS_STARTING_CHOOSING);
      }

      try {
        const url = "/api/chats?gender=" + search;

        const headers: any = {};

        if (flag) {
          headers["Authorization"] = localStorage.getItem("_accessToken");
        }

        const { data } = await axiosInstance.get<{ items: any[] }>(url, {
          headers,
        });

        const sortedItem = sortByDateDesc(data.items);

        set({ modelsChats: sortedItem });
      } catch (e) {
        console.log(e);
      } finally {
        get().setModelChatsLoading(false);
      }
    },
    fetchModelsChatsAndSetFirstActiveModel: async (search?: string) => {
      try {
        let url = `/api/chats`;

        if (search) {
          url += "?search=" + search;
        }

        const { data } = await axiosInstance.get<{ items: any[] }>(url, {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        });

        const sortedItem = sortByDateDesc(data.items);
        const chat = sortedItem[0];
        chat.model.first_msg = chat.last_message.text;

        set({ modelsChats: sortedItem });
        set({ modelActive: sortedItem[0] });

        return chat?.model?.id;
      } catch (e) {
        console.log(e);
      }
    },
    fetchAnonymousModelsChats: async () => {
      let genderType = localStorage.getItem(IS_STARTING_CHOOSING);

      try {
        const url = "/api/anonym/chats?gender=" + genderType;
        const { data } = await axiosInstance.get<{ items: any[] }>(url, {
          headers: {
            Authorization: null,
          },
        });

        const message = {
          from: "model",
          image_url: "",
          send_at: new Date().toLocaleString().replace(",", "").slice(11, 16),
          text: data.items[0].model.first_msg,
          type: "empty",
        };

        if (data.items.length) {
          set({
            modelsChats: data.items,
            modelActive: data.items[0],
            messages: [message],
            disabledBtn: false,
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    fetchModel: async (id: string) => {
      try {
        const url = `/api/models/${id}`;
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        const { data } = await axiosInstance.get<any>(url, axiosConfig);

        var date = new Date();

        const lastMessageWithText = {
          from: "model",
          image_url: "",
          send_at: date.toISOString(),
          text: data.first_msg,
        };

        set({
          modelActive: { model: data, last_message: lastMessageWithText },
        });
      } catch (e) {
        console.log(e);
      }
    },
    fetchModelBundles: async (genderType: string) => {
      try {
        const url = `/api/models/bundles?gender=${genderType}`;
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };
        const { data } = await axiosInstance.get<any>(url, axiosConfig);

        set({ modelsBundles: data.items });
      } catch (e) {
        console.log(e);
      }
    },
    fetchModelBundleItem: async (modelId: number) => {
      try {
        const genderType = localStorage.getItem(IS_STARTING_CHOOSING);

        const url = `/api/bundles?model_id=${modelId}&gender=${genderType}`;
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        const { data } = await axiosInstance.get<any>(url, axiosConfig);

        set({ modelBundles: data.bundles });
      } catch (e) {
        console.log(e);
      }
    },
    bundlePayment: async (bundleId: number) => {
      const url = `/api/bundles/${bundleId}/payment`;

      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const { data } = await axiosInstance.post<any>(url, {}, axiosConfig);

      return data.current_tokens;
    },
    setBundleProp: (id: number, props: any) => {
      const modelBundles = get().modelBundles;

      const updatedBundles = modelBundles.map((bundle) => {
        if (bundle.id === id) {
          bundle = { ...bundle, ...props };
        }

        return bundle;
      });
      set({ modelBundles: updatedBundles });
    },
    fetchPack: async (id: string) => {
      const search = localStorage.getItem(IS_STARTING_CHOOSING);

      try {
        const url = `/api/bundles/${id}/items?gender=${search}`;
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        const { data } = await axiosInstance.get<any>(url, axiosConfig);

        set({ modelsBundles: data.items });
      } catch (e) {
        console.log(e);
      }
    },
    getChatMessages: async (modelId: string, page: number = 0) => {
      const currentMessages = get().messages;
      const defaultLimit = 10;

      const chats = get().modelsChats || [];

      const activeChat = chats.find((chat: IChatItem) => chat.model.id === +modelId);

      if (!activeChat) {
        return;
      }

      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const url = `/api/chats/${activeChat.id}/messages?limit=${defaultLimit}&page=${page}&reverse=true`;
      const { data } = await axiosInstance.get<any>(url, axiosConfig);

      const items = data.items;
      if (items.length === 1 && items[0].text === "") {
        items[0].text = activeChat.model.first_msg;
      }

      const lastMessageType = items[items.length - 1]?.type;

      if (lastMessageType === "start_generation" || lastMessageType === "typing") {
        set({ disabledBtn: true });
      } else {
        set({ disabledBtn: false });
      }

      // const privateMessage = {
      //   type: "firstImg",
      //   id: "67ccbe215be30117731ad5ebbb",
      //   text: "Hello, I’m Chloe, art dealer. *A playful smile appears..* What brings you to my gallery tonight?",
      //   images_urls: ["https://charmai_media_staging.storage.googleapis.com/chats/image_1741471311818089785.png"],
      //   send_at: "2025-03-08T22:01:06.078Z",
      //   from: "model",
      // };

      // const privateMessageVideo = {
      //   from: "model",
      //   id: "67d0099f5ff99b4cba7e4bc6",
      //   images_urls: null,
      //   is_blured: false,
      //   send_at: "2025-03-11T09:59:59.044Z",
      //   media_url: [
      //     "https://charmai_media_staging.storage.googleapis.com/bundles/videos/Gen_3_Alpha_Turbo_3481293443_video_of_model_in_s_Cropped_00863.mp4",
      //   ],
      //   type: "video",
      // };

      //privateMessage, privateMessageVideo,
      if (currentMessages?.length) {
        set({ messages: [...currentMessages, ...items.reverse()] });
      } else {
        set({ messages: items.reverse() });
      }

      set({ totalCount: data.total_count });
      set({ scrollToLast: "allMessages" });
    },
    setModelsChats: (modelsChats?: any[]) => {
      if (!modelsChats) {
        set({ modelsChats });
      } else {
        set({ modelsChats: undefined });
      }
    },
    addChatFirst: (id: number) => {
      const modelsChats = get().modelsChats;
      const existedChat = modelsChats?.find((chat) => chat.id === id);

      if (existedChat) {
        let modelsChatFiltered = modelsChats?.filter((chat) => chat.id !== existedChat.id) || [];

        set({ modelsChats: [existedChat, ...modelsChatFiltered] });
      }
    },
    saveChat: async (id: string) => {
      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const body = {
        model_id: +id,
      };

      try {
        const url = `/api/chats`;
        const { data } = await axiosInstance.post<{ chat: any }>(url, body, axiosConfig);

        set({ modelActive: data.chat });
      } catch (e) {
        console.log(e);
      }
    },
    addModelToModelsChat: () => {
      const chats = get().modelsChats || [];

      const activeChat = get().modelActive;

      const modelId = Number(activeChat?.model?.id) || null;

      const isChatExist = modelId && chats.some((chat) => chat?.model?.id === modelId);

      if (isChatExist) {
        return;
      }

      set({
        modelsChats: [{ id: 0, ...activeChat }, ...chats],
      });
    },
    addModelToModelsChatWithParams: (activeModel: any) => {
      const chats = get().modelsChats || [];

      set({
        modelsChats: [{ id: 0, ...activeModel }, ...chats],
      });
    },
    addEmptyMessage: () => {
      const activeModel = get().modelActive;

      const message = {
        from: "model",
        image_url: "",
        send_at: new Date().toLocaleString().replace(",", "").slice(11, 16),
        text: activeModel.model?.first_msg || "",
      };

      set({ messages: [message] });
    },
    restartChat: async (chatId: string) => {
      try {
        try {
          const axiosConfig = {
            headers: {
              Authorization: localStorage.getItem("_accessToken"),
            },
          };

          const url = `/api/chats/${chatId}/messages/delete_all`;

          await axiosInstance.put<any>(url, {}, axiosConfig);
          get().setLastMessage(+chatId, lastMessage);
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    },
    deleteChat: async (chatId: string) => {
      try {
        const url = "/api/chats/" + chatId;
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        await axiosInstance.delete<{ items: IModel[] }>(url, axiosConfig);

        const chats = get().modelsChats;

        set({
          modelActive: modelActive,
          modelsChats: chats?.filter((chat) => chat.id !== +chatId),
        });
      } catch (e) {
        console.log(e);
      }
    },
    deleteMessage: async (id: string, chatId: string) => {
      try {
        const url = "/api/chats/" + chatId + "/messages/delete";
        const body = {
          msgs_ids: [id],
        };

        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        await axiosInstance.put<{ items: IModel[] }>(url, body, axiosConfig);

        const messages = get().messages?.filter((item) => item.id !== id);

        set({ messages: messages });
      } catch (e) {
        console.log(e);
      }
    },
    setModels: (models: []) => {
      set({ models: models });
    },
    setBundles: (bundles: any[]) => {
      set({ modelBundles: bundles });
    },
    setActiveModel: (activeModel?: IModel) => {
      const lastActiveMessage = activeModel && { text: activeModel?.first_msg || "", from: "model" };

      set({
        modelActive: {
          model: activeModel || model,
          last_message: lastActiveMessage || lastMessage,
        },
      });
    },
    setLastMessage: (chatId: any, message: any) => {
      if (!chatId) {
        return;
      }

      const chats = get().modelsChats;

      set({
        modelsChats: chats?.map((chat) => {
          if (chatId === chat.id) {
            chat.last_message = message;
          }

          return chat;
        }),
      });
    },
    sendMessage: async (chatId: string, message: string) => {
      const messages = get().messages || [];

      const newMessage = {
        from: "user",
        image_url: "",
        send_at: new Date().toLocaleString().replace(",", "").slice(11, 16),
        text: message,
      };

      set({ messages: [newMessage, ...messages] });
      set({ scrollToLast: "oneMessage" });
      try {
        const body = {
          message: {
            text: message,
          },
        };

        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };
        const url = `/api/chats/${chatId}/messages`;
        const { data } = await axiosInstance.post<any>(url, body, axiosConfig);

        return data;
      } catch (e: any) {
        if (e?.response?.status === 402) {
          const messages = get().messages || [];
          messages.pop();
          set({ messages: messages });

          return {
            status: 402,
            type: e.response.data.type,
            error: e.response?.data?.error,
          };
        }

        console.log(e);
      }
    },
    setMessages: (messages: any[] | null) => {
      set({ messages: messages });
    },
    setMessage: async (message: any) => {
      let messages = get().messages || [];

      const activeModel = get().modelActive;
      const currentChat = activeModel?.model.id === +message.model_id;

      if (!currentChat) {
        return;
      }

      const isMessageImg = message.type === "image";

      if (isMessageImg) {
        messages = messages.filter((item: IMessage) => item.type !== "start_generation");
      }

      const isMessageAlreadyExist = messages.find((item: IMessage) => item.id === message.id);

      if (!isMessageAlreadyExist) {
        set({ messages: [message, ...messages] });
      }
    },
    setGalleryPack: async (data: any) => {
      set({ galleryPack: data });
    },
    setModelChatsLoading: (flag: boolean) => {
      set({ isModelsChatsLoading: flag });
    },
    setMessagesLoading: (flag: boolean) => {
      set({ isMessagesLoading: flag });
    },
    setModelActiveLoading: (flag: boolean) => {
      set({ isModelActiveLoading: flag });
    },
    setIsTyping: (flag: boolean, message?: any) => {
      const messages = get().messages || [];

      if (flag) {
        set({ messages: [message, ...messages] });
      } else {
        const messagesWithoutTyping = messages.filter((item) => item.type !== "typing");
        set({ messages: [...messagesWithoutTyping] });
      }
    },
    setGenerationProgress: (flag: boolean, message?: any) => {
      if (message) {
        const activeModel = get().modelActive;
        const currentChat = activeModel?.model?.id === +message.model_id;
        if (currentChat) {
          set({ isGenerationInProgress: flag });
        }
      } else {
        set({ isGenerationInProgress: flag });
      }
    },
    setScrollToLast: (flag: string) => {
      set({ scrollToLast: flag });
    },
    checkAiGenerationStatus: async (aiProcessingId: string) => {
      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const url = `/api/models/${aiProcessingId}`;
      const { data } = await axiosInstance.get<any>(url, axiosConfig);

      return data;
    },
  }))
);
